import { useCallback, useEffect, useRef, useState } from "react";
import { Nullable, TriggeredHook } from "sonobello.utilities.react";

import EnvironmentConfiguration from "../../../../constants/EnvironmentConfiguration";
import { HttpStatusCode } from "../../../../types/HttpStatusCodes";
import useObx from "../../../../utils/UseObx";
import CalendarSlotStatus from "../../../Calendar/Types/CalendarSlotStatus";
import { ICalendarService, IScheduleSlot } from "../../../Calendar/Types/ICalendar";
import { OpsScheduleSlot } from "../../../Calendar/Types/OpsCalendar";
import ApiRequestPaths from "../../../Constants/ApiRequestPaths";
import IReservation from "../../../Types/IReservation";
import OpsReservation from "../../../Types/OpsReservation";
import { IUseCreateOpsReservationProps } from "./UseCreateHybridReservation";
import { ReservationRequest } from "./UseCreateLegacyReservation";

export class ReservationRequestWithDistance extends ReservationRequest {
  /** The distance in miles from the customer to the center. */
  distanceToCenterInMiles: Nullable<number>;

  constructor(
    slot: Pick<IScheduleSlot, "startTimeUtc" | "endTimeUtc">,
    calendarSlotStatus?: CalendarSlotStatus,
    distanceToCenterInMiles?: Nullable<number>
  ) {
    super(slot, calendarSlotStatus);

    this.distanceToCenterInMiles =
      distanceToCenterInMiles ?? EnvironmentConfiguration.optimizedPatientScheduler.fallbackCustomerDistanceToCenter;
  }
}

/** Handles the network requests necessary to make reservation requests for the OPS calendar process and
 * passes the customer's distance to center along with the request.
 */
const useCreateOpsDistanceReservation: TriggeredHook<
  IReservation,
  OpsScheduleSlot,
  boolean,
  IUseCreateOpsReservationProps
> = props => {
  const propsRef = useRef<IUseCreateOpsReservationProps>(props);
  const [result, setResult] = useState<Nullable<IReservation>>(null);

  useEffect(() => {
    propsRef.current = props;
  }, [props]);

  const { res, err, loading, setReq } = useObx<
    void,
    ReservationRequestWithDistance,
    { service: ICalendarService; slot: OpsScheduleSlot }
  >("Post Reservation", { method: "post" });

  useEffect(() => {
    if (res?.code !== HttpStatusCode.Accepted) return;
    setResult(new OpsReservation(propsRef.current.center, res.request.custom!.service, res.request.custom!.slot));
  }, [res]);

  const execute = useCallback(
    (slotToReserve: OpsScheduleSlot) =>
      setReq(
        r =>
          r && {
            ...r,
            url: ApiRequestPaths.postOpsReservationUrlV2(
              propsRef.current.leadId,
              propsRef.current.customerId,
              propsRef.current.center.id,
              propsRef.current.service.id,
              slotToReserve.id
            ),
            payload: new ReservationRequestWithDistance(slotToReserve, undefined, propsRef.current.center.distance),
            custom: {
              service: propsRef.current.service,
              slot: slotToReserve
            }
          }
      ),
    []
  );

  return {
    result,
    isLoading: loading,
    error: Boolean(err && err.error.response?.status !== HttpStatusCode.Unauthorized),
    execute
  };
};

export default useCreateOpsDistanceReservation;
