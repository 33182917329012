import { BucketedVariation as ConvertBucketedVariation } from "@convertcom/js-sdk";

import ExperimentConfiguration from "./ExperimentConfiguration";

/** An {@link ExperimentConfiguration} for use with Convert.com experiments. */
class ConvertExperimentConfiguration extends ExperimentConfiguration {
  /**
   * Initializes a new instance of the {@link ConvertExperimentConfiguration} class.
   * @param experience - The Convert.com bucketed variation to configure the experiment with.
   * @remarks The experiment is active if the variation's key contains the token `original-page`. When experiments are
   * created in Convert.com the first variation should automatically be assigned a key containing this token by the
   * system.
   */
  constructor(experimentId: string, experience: ConvertBucketedVariation) {
    super(experimentId, !experience.key.includes(ConvertExperimentConfiguration.ConvertFirstExperienceKeyToken));
  }

  /**
   * The partial token for {@link ConvertBucketedVariation.key} assigned by Convert.com when the variation is created.
   */
  static ConvertFirstExperienceKeyToken = "original-page";
}

export default ConvertExperimentConfiguration;
